import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Site from './main-page';




// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Site/>);
