import React from "react";
import NavItems from './navitems.jsx';
import {BrowserRouter as Router} from 'react-router-dom';

const NavBar = ({changeClass, pages}) => 
{

    return(
        <div className="navitems">
            <Router>
            {pages.map((el,i) =>
                {
                    return(<NavItems changeClass={changeClass} key={i} link={el[0]} navtext={el[1]}/>);
                })
            }
            </Router>
        </div>
    )
};

export default NavBar;