import React from 'react';


export default class EmailField extends React.Component
{
    onEmailChange(event) {
        this.props.handleEmailChange(event);
      }
    

    render(){
        return(<div>
        <div  className="formLabel"><label htmlFor="contactEmail" className="formLabel">Email:</label></div>
        <input 
            name="contactEail" 
            className = "textField" 
            id="contactEmail" 
            value={this.props.value} 
            onChange={this.onEmailChange.bind(this)}
        />
        <br/>
        </div>
        )
    }
}