import React from 'react';

const FormError = ({message}) =>{

    return(
    <div className="formError">
        {message}
    </div>
    );
}


const FormErrors = ({errors}) => 
{
    return(
        <div className="formerrors">
            <div className="nameErrors">
                {errors.name.map((el)=> {return(<FormError message = {el}/>)})}
            </div>
            <div className="phoneErrors">
                {errors.phone.map((el)=> {return(<FormError message = {el}/>)})}
            </div>
            <div className="emailErrors">
                {errors.email.map((el)=> {return(<FormError message = {el}/>)})}
            </div>
            <div className="messageErrors">
                {errors.message.map((el)=> {return(<FormError message = {el}/>)})}
            </div>
        </div>
    );
};

export default FormErrors;