import Header from "./header";
import NavBar from "./navigation/navbar";

const UpperPart = ({classChange, classVal, pagelist}) => {


    return (
        <div className={classVal} id="home">
            <div className="header"><Header/></div>
            <div className="navbar"><NavBar changeClass={classChange} pages={[...pagelist]}/></div>
            <div className="logo">
                <a href="/">
                <img src="images/FMASingle_white.png" width="90vw" height="90vw" alt="Airplane"/>
                </a>
            </div>
        </div>
    );
};

export default UpperPart;
