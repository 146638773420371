import React from "react";
import ContactForm from './contactform.jsx';

const ContactUs = () =>
{
    return (
        <div id="ContactUs">
        <div className="sectionTitle">Contact Us</div><br/>
        <div className="sectionText">
          <p>Please contact us and let us know how we can help you</p>
          <div><ContactForm/></div>
        </div>
      </div>
    )

};

export default ContactUs;