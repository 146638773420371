import React,{useState} from 'react';
import EmailField from '../emailfield.jsx';
import FormErrors from './formerrors.jsx';
import ConfigData from '../settings.json';

const ContactForm = () => 
{
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [formErrors, setFormErrors] = useState({name:[], email: [], phone: [], message: []});
  
  
    const handleSubmit = (e) => {
      e.preventDefault();

      if (validateForm())
      {
        fetch(ConfigData.emailer, {
          method: "POST",
          body: JSON.stringify({"name":name,"email":email,"phone":phone,"message":message}),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        }).then(
          (response) => (response.json())
            ).then((response)=> {
          if (response.status === 'success') {
            alert("Message Sent.");
            resetForm()
          } else if(response.status === 'fail') {
            alert("Message failed to send.")
          }
        })
      } 
    }
    const resetForm = () => {
      setName("");
      setEmail("");
      setMessage("");
      setPhone("");
      setFormErrors({name:[], email: [], phone: [], message: []});
    //  setNameError([]);
    //  setEmailError([]);
    //  setMessageError([]);
    //  setPhoneError([]);
    }

    const validateForm = () =>{
      let valErrors = {name:[], email:[], message:[], phone:[]};
      let formIsValid = true;

      if (email !== ''){
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");
  
        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            email.length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          console.log("Email is not valid");
          valErrors.email = [...valErrors.email, "Email is not valid"];
        }
      } else {
        formIsValid = false;
        valErrors.email = [...valErrors.email,'Email must not be blank'];
      }
      if(name === '')
      {        
        formIsValid = false;
        valErrors.name = [...valErrors.name,'Name must not be blank'];
      }
      if(phone === '')
      {        
        formIsValid = false;
        valErrors.phone = [...valErrors.phone, 'Phone must not be blank'];
      }      
      if(message === '')
      {        
        formIsValid = false;
        valErrors.message = [...valErrors.message,'Message must not be blank'];
      }

      setFormErrors({
        name:[...valErrors.name],
        email:[...valErrors.email],
        phone:[...valErrors.phone],
        message:[...valErrors.message]
      });

      return(formIsValid);
    }

    const onNameChange = (event) => {
      setName(event.target.value);
    }
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    }
    const onMessageChange = (event) => {
      setMessage(event.target.value);
    }
    const onPhoneChange = (event) => {
      setPhone(event.target.value);
    }
  
    
    return(
    <div>
      <FormErrors errors={formErrors}/>
      <form id="contactForm" onSubmit={handleSubmit}  method="POST">
          <div  className="formLabel"><label htmlFor="contactName">Name:</label></div> 
          <input name="contactName" className = "textField" id="contactName" value={name} onChange={onNameChange}/><br/>
          <div  className="formLabel"><label htmlFor="contactPhone" className="formLabel">Phone:</label></div>
          <input name="contactPhone" className = "textField" id="contactPhone" value={phone} onChange={onPhoneChange}/><br/>
          <EmailField value={email} handleEmailChange={handleEmailChange}/>
          <div  className="formLabel"><label htmlFor="contactMessage" className="formLabel">How can we help you:</label></div>
          <textarea name ="contactMessage" className = "textArea" id="contactMessage" rows="10" value={message} onChange={onMessageChange}/><br/>
          <div className="formLabel">&nbsp;</div><button id="contactSubmit" name = "Submit" className="myButton">Send Us A Message</button>
      </form>
    </div>
    );
  };

  export default ContactForm;