import React from "react";

const AdGraphic = () =>
{
    return (
        <div className = "homeArea">
            <div className="tagArea">
                <div className="leftTag">We take aircraft<br/>from ramp tramps ...</div>
                <div className="rightTag">... to sky high<br/>beauties</div>
            </div>
        </div>
    );
};

export default AdGraphic;