import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavItems = ({changeClass, link, navtext}) =>
{
    const history = useNavigate();

    const setActive = (link)=> {
        history(`/${link}`);
    }
    return (
            <a href="#" className="navitem" onClick={()=>{
                changeClass("mainbody");
                setActive(link);
            }}>{navtext}</a>
    );
};

export default NavItems;