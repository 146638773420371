import React from "react";
import {BrowserRouter as Router,} from 'react-router-dom';
import FooterNav from "./footerNav";

const Footer = ({classChange}) =>
{    
    return (
    <div className="footerArea">
            <div className="footerMenu">
                <Router>
                    <FooterNav classChange={classChange}/>
                </Router>
            </div>
        <div className="footerCompanyInfo">
            Flying Metal Aviation, LLC<br/>
            507 Coalbrook Dr.<br/>
            Midlothian, VA 23114<br/>
            (910) 297-0929
        </div>
    </div>
    )
};

export default Footer;