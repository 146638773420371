import React from "react";
import Footer from "../footer";
import {useState} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AboutUs from "../aboutus";
import ContactUs from "../contactus/contactus";
import AdGraphic from "../adgraphic";
import UpperPart from "../upperPart";

const Site = () => {
    const pages = [["AboutUs","ABOUT US"],["ContactUs", "CONTACT US"]];

    const [currentBodyClass, setCurrentBodyClass] = useState("homepageBody");

    const setMainBodyClass = (newClass) => {
      console.log(`Changing Class to ${newClass}`);
      setCurrentBodyClass(newClass);
    };

    return (
      
        <div className="homepage">
          <UpperPart classChange={setMainBodyClass} classVal = {currentBodyClass} pagelist={[...pages]}/>
          <div className="contentArea">
          <Router>
            <Routes>
              <Route path="/AboutUs" element={<AboutUs/>}/>
              <Route path="/ContactUs" element={<ContactUs />}/>
              <Route path="/" element={<AdGraphic/>}/>
            </Routes>
          </Router>
          </div>
          <div className="footer"><Footer classChange={setMainBodyClass}/></div>
        </div>
    );
};

export default Site;