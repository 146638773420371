import React from 'react';
import { useNavigate } from 'react-router-dom';

const FooterNav = ({classChange}) =>
{
    const history = useNavigate();

    const setActive = (link)=> {
        history(`/${link}`);
    }
    return (
        <div>
            <a className="footerMenuItem" href="#" onClick={()=>{
                classChange("homepageBody");
                setActive('/');
            }}>HOME</a><br/>
            <a className="footerMenuItem" href="#" onClick={()=>{
                    classChange("mainbody");
                    setActive('AboutUs');
                }}>ABOUT US</a><br/>
            <a className="footerMenuItem" href="#" onClick={()=>{
                    classChange("mainbody");
                    setActive('ContactUs');
                }}>CONTACT US</a>
        </div>
    );
};

export default FooterNav;